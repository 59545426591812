<script setup lang="ts">
import {
  useProductPrice,
  useCmsTranslations,
} from "@shopware/composables";
import type { Schemas } from "#shopware";
import SwSharedPrice from "../shared/SharedPrice.vue";
import { toRefs } from "vue";
import { defu } from "defu";

const props = defineProps<{
  product: Schemas["Product"];
}>();

type Translations = {
  listing: {
    variantsFrom: string;
    previously: string;
    to: string;
  };
};

let translations: Translations = {
  listing: {
    variantsFrom: "variants from",
    previously: "previously",
    to: "to",
  },
};

translations = defu(useCmsTranslations(), translations) as Translations;

const { product } = toRefs(props);

const {
  price,
  unitPrice,
  displayFromVariants,
  displayFrom,
  isListPrice,
  regulationPrice,
} = useProductPrice(product);
</script>

<template>
  <div :id="product.id">
    <SwSharedPrice
      v-if="isListPrice"
      class="text-sm text-gray-900 font-bold basis-2/6 justify-end line-through mr-2"
      :value="price?.listPrice?.price"
    />
    <SwSharedPrice
      v-if="displayFromVariants"
      class="text-xl text-gray-900 font-bold basis-2/6 justify-end"
      :value="displayFromVariants"
    >
      <template #beforePrice
        ><span v-if="displayFromVariants" class="text-sm">{{
          translations.listing.variantsFrom
        }}</span></template
      >
    </SwSharedPrice>
    <SwSharedPrice
      class="text-gray-900 text-sm font-bold basis-2/6"
      :class="{
        'text-red-600': isListPrice,
        'justify-end text-l':
          regulationPrice || !regulationPrice || !displayFromVariants,
      }"
      :value="unitPrice"
    >
      <template #beforePrice
        ><span v-if="displayFrom || displayFromVariants" class="text-sm">{{
          translations.listing.to
        }}</span></template
      >
    </SwSharedPrice>
    <template v-if="regulationPrice">
      <div class="flex gap-2 font-bold justify-end text-gray-500 text-3.5 mb-2">
        {{ translations.listing.previously }}
        <SharedPrice :value="regulationPrice" />
      </div>
    </template>
  </div>
</template>
